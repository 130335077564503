import { Navbar as BootstrapNavbar, Container, NavDropdown, Modal } from "react-bootstrap";
import { AccountSettings, useAuthenticator } from "@aws-amplify/ui-react";
import { useState } from "react";

const ChangePasswordModal = ({
    show,
    setShow,
}: {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Change Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AccountSettings.ChangePassword onSuccess={() => setShow(false)} />
            </Modal.Body>
        </Modal>
    );
};

const Navbar = () => {
    const { user, signOut } = useAuthenticator((context) => [context.user]);
    const [showChangePasswordModal, setShowChangePasswordModal] = useState<boolean>(false);

    return (
        <BootstrapNavbar bg="dark" variant="dark" sticky="top">
            <ChangePasswordModal show={showChangePasswordModal} setShow={setShowChangePasswordModal} />
            <Container>
                <BootstrapNavbar.Brand href="/">Potentium Environment Manager</BootstrapNavbar.Brand>

                <NavDropdown
                    className="justify-content-end"
                    align="end"
                    menuVariant="dark"
                    title={`Signed in as: ${user.attributes?.name}`}
                    id="user-dropdown"
                >
                    <NavDropdown.Item onClick={() => setShowChangePasswordModal(true)}>
                        Change Password
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={signOut}>Sign Out</NavDropdown.Item>
                </NavDropdown>
            </Container>
        </BootstrapNavbar>
    );
};

export default Navbar;
