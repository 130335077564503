import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import "./App.scss";
import Navbar from "./components/Navbar";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { RouterProvider } from "react-router-dom";
import router from "./router";

Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: "ap-southeast-2",
        userPoolId: "ap-southeast-2_Z1nTt4DVO",
        userPoolWebClientId: "64ibssmnjkv6t5gpq80u7b9k9s",
    },
});

const queryClient = new QueryClient();

const App = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <Navbar />
            <RouterProvider router={router} />
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
};

export default withAuthenticator(App, { hideSignUp: true });
