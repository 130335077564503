export interface Environment {
    being_modified: boolean;
    name: string;
    description?: string;
    last_modified_at: string;
    last_modified_by: string;
    lifestage: EnvironmentLifestage;
    component_versions: ComponentVersion[];
}

export enum EnvironmentLifestage {
    CREATED = "Created",
    PERSISTENTACTIVE = "Persistent Active",
    PREACTIVE = "Pre-Active",
    ACTIVE = "Active",
}

export interface ComponentVersion {
    component: string;
    version: string;
}

export interface EnvironmentCreationPayload {
    name: string;
    description?: string;
    component_versions: ComponentVersion[];
}

export interface EnvironmentCreationForm {
    name: string;
    description?: string;
    componentVersions: object;
}

export interface EnvironmentPatchPayload {
    description?: string;
    component_versions?: ComponentVersion[];
    refresh_components?: string[];
}

export interface EnvironmentEditForm extends EnvironmentPatchPayload {
    refreshComponents?: string[];
}
