import { createBrowserRouter } from "react-router-dom";
import EnvironmentDetail from "./pages/EnvironmentDetail/EnvironmentDetail";
import Environments from "./pages/Environments/Environments";
import NotFound from "./pages/NotFound";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Environments />,
    },
    {
        path: "/environments",
        element: <Environments />,
    },
    {
        path: "/environments/:envName",
        element: <EnvironmentDetail />,
    },
]);

export default router;
