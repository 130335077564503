import {
    faBomb,
    faClipboard,
    faClipboardCheck,
    faCloud,
    faGlobe,
    faHouseMedicalFlag,
    faIcicles,
    faKey,
    faPlus,
    faRocket,
    faRotate,
    faSpinner,
    faSyringe,
    faToiletPaper,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import {
    Alert,
    Button,
    ButtonGroup,
    Card,
    Col,
    Dropdown,
    Form,
    ListGroup,
    Modal,
    OverlayTrigger,
    ProgressBar,
    Row,
    Spinner,
    SplitButton,
    Tooltip,
} from "react-bootstrap";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import EnvironmentLogs from "../../components/EnvironmentLogs/EnvironmentLogs";
import { Environment, EnvironmentLifestage } from "../../types/Environment";
import { EventType } from "../../types/Event";
import axios from "../../utils/axios";
import { SpaceBetween } from "../../utils/spacing";
import { EnvironmentCreationModal } from "../Environments/Environments";
import * as Yup from "yup";
import "./EnvironmentDetail.scss";
import { InitialisationPayload } from "../../types/Initialisation";

enum Action {
    APPLY = "apply",
    DESTROY = "destroy",
}

enum Component {
    PERSISTENT = "Persistent",
    EPHEMERAL = "Ephemeral",
}

const EnvironmentInitModal = ({
    show,
    setShow,
    env,
}: {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    env: Environment;
}) => {
    const initialisationMutation = useMutation((data: InitialisationPayload) =>
        axios.put(
            `https://${env.name}.potentium.com.au/api/games/initialise`,
            {},
            {
                params: data,
            },
        ),
    );

    const initKeyQuery = useQuery({
        queryKey: ["initKey", env.name],
        queryFn: async () => {
            const res = await axios.get(`/environments/${env.name}/init_key`);
            return res.data;
        },
        enabled: show,
    });

    const openapiJSONQuery = useQuery({
        queryKey: ["openapiJSON", env.name],
        queryFn: async () => {
            const res = await axios.get(`https://${env.name}.potentium.com.au/api/openapi.json`);
            return res.data;
        },
        enabled: show,
    });

    const [confirmText, setConfirmText] = useState<string>("");

    const isLoading = initKeyQuery.isLoading || openapiJSONQuery.isLoading;
    const isSuccess = initKeyQuery.isSuccess && openapiJSONQuery.isSuccess;

    const schema = Yup.object().shape({
        gameName: Yup.string().max(50, "Game name must be 50 characters or less"),
        gameChoice: Yup.string().required("Scenario is required"),
    });

    const getProgressBarVariant = () => {
        if (confirmText.length < "confirm".length / 3) {
            return "success";
        }
        if (confirmText.length < ("confirm".length / 3) * 2) {
            return "warning";
        }
        if (confirmText.length < "confirm".length) {
            return "danger";
        }
    };

    return (
        <Formik
            initialValues={{
                initKey: "",
                gameName: "",
                gameChoice: "",
                offline: false,
                createUsers: false,
                allAdmins: false,
            }}
            validationSchema={schema}
            onSubmit={(values: InitialisationPayload, { resetForm }) => {
                Object.keys(values).forEach(
                    (key: string) =>
                        values[key as keyof typeof EnvironmentInitModal] === "" &&
                        delete values[key as keyof typeof EnvironmentInitModal],
                );
                let payload = {
                    ...values,
                    initKey: initKeyQuery.data,
                };
                initialisationMutation.mutate(payload);
                setConfirmText("");
                setShow(false);
                resetForm();
            }}
        >
            {({ values, handleChange, handleSubmit, isValid, errors, touched }) => (
                <Modal
                    show={show}
                    onHide={() => {
                        setConfirmText("");
                        setShow(false);
                    }}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Initialise <code>{env.name}</code>
                        </Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={handleSubmit}>
                        <Modal.Body>
                            {isLoading && (
                                <div className="d-flex flex-column align-items-center">
                                    <Spinner />
                                </div>
                            )}
                            {isSuccess && (
                                <Fragment>
                                    <Alert variant="warning">
                                        <FontAwesomeIcon icon={faBomb} />
                                        &nbsp; This will destroy all data in the environment and reinitialise. This
                                        action may and will likely result in unrecoverable data loss. Please ensure you
                                        have a backup of the environment before proceeding.
                                        {confirmText !== "confirm" && (
                                            <>
                                                <hr />
                                                If you are sure, type <code>confirm</code> in the box below.
                                            </>
                                        )}
                                    </Alert>
                                    {confirmText !== "confirm" && (
                                        <>
                                            <Form.Group controlId="confirmText">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="confirm"
                                                    value={confirmText}
                                                    onChange={(e) => setConfirmText(e.target.value)}
                                                />
                                            </Form.Group>
                                            {confirmText.toLowerCase() !== "confirm" && (
                                                <ProgressBar
                                                    variant={getProgressBarVariant()}
                                                    className="mt-3"
                                                    now={confirmText.length}
                                                    max={"confirm".length}
                                                />
                                            )}
                                        </>
                                    )}
                                    {confirmText === "confirm" && (
                                        <SpaceBetween>
                                            <Form.Group controlId="gameName">
                                                <Form.Label>Game Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="(Optional) Specify game name"
                                                    value={values.gameName}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.gameName}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.gameName}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group controlId="gameChoice">
                                                <Form.Label>Scenario</Form.Label>
                                                <Form.Select
                                                    value={values.gameChoice}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.gameChoice}
                                                    placeholder="Select scenario"
                                                >
                                                    <option value="">Select scenario</option>
                                                    {openapiJSONQuery.data.paths["/api/games/initialise"].put.parameters
                                                        .find((p: any) => p.name === "gameChoice")
                                                        .schema.enum.map((c: string) => (
                                                            <option key={c} value={c}>
                                                                {c.charAt(0).toUpperCase() + c.slice(1)}
                                                            </option>
                                                        ))}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.gameChoice}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <div className="d-flex justify-content-center mt-3">
                                                <Form.Group controlId="offline" className="mx-2">
                                                    <Form.Check
                                                        type="checkbox"
                                                        label="Offline"
                                                        checked={values.offline}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>
                                                <Form.Group controlId="createUsers" className="mx-2">
                                                    <Form.Check
                                                        type="checkbox"
                                                        label="Create Users"
                                                        checked={values.createUsers}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>
                                                <Form.Group controlId="allAdmins" className="mx-2">
                                                    <Form.Check
                                                        type="checkbox"
                                                        label="Create Admins"
                                                        checked={values.allAdmins}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </SpaceBetween>
                                    )}
                                </Fragment>
                            )}
                        </Modal.Body>
                        {isSuccess && confirmText === "confirm" && (
                            <Modal.Footer>
                                <Button type="submit" variant="primary" disabled={!isValid}>
                                    Initialise
                                </Button>
                            </Modal.Footer>
                        )}
                    </Form>
                </Modal>
            )}
        </Formik>
    );
};

interface Key {
    name: string;
    friendly_name: string;
    value: string;
}

const EnvironmentKeyRow = ({ envKey }: { envKey: Key }) => {
    const [copyButtonClicked, setCopyButtonClicked] = useState<boolean>(false);

    useEffect(() => {
        if (copyButtonClicked) {
            setTimeout(() => {
                setCopyButtonClicked(false);
            }, 2000);
        }
    }, [copyButtonClicked]);

    return (
        <div className="key-row">
            <div className="key-name">{envKey.friendly_name}:</div>
            <div className="key-box">
                <code>{envKey.value}</code>
            </div>
            <div className="key-copy-button">
                <Button
                    onClick={() => {
                        navigator.clipboard.writeText(envKey.value);
                        setCopyButtonClicked(true);
                    }}
                    variant=""
                >
                    <OverlayTrigger placement="right" overlay={<Tooltip>Copy to clipboard</Tooltip>}>
                        {copyButtonClicked ? (
                            <FontAwesomeIcon icon={faClipboardCheck} size="xl" />
                        ) : (
                            <FontAwesomeIcon icon={faClipboard} size="xl" />
                        )}
                    </OverlayTrigger>
                </Button>
            </div>
        </div>
    );
};

const EnvironmentKeysModal = ({
    show,
    setShow,
    env,
}: {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    env: Environment;
}) => {
    const keysQuery = useQuery({
        queryKey: ["keys", env.name],
        queryFn: async () => {
            const res = await axios.post(`/environments/${env.name}/keys`, ["init_key", "keycloak_admin_password"]);
            return res.data;
        },
        enabled: show,
    });

    return (
        <Modal
            show={show}
            onHide={() => {
                setShow(false);
            }}
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Keys for <code>{env.name}</code>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {keysQuery.isLoading && (
                    <div className="d-flex flex-column align-items-center">
                        <Spinner />
                    </div>
                )}
                {keysQuery.isSuccess && (
                    <div className="keys-modal">
                        {keysQuery.data.map((envKey: Key) => (
                            <EnvironmentKeyRow envKey={envKey} key={envKey.name} />
                        ))}
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
};

const EnvironmentPlanModal = ({
    show,
    setShow,
    env,
    component,
}: {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    env: Environment;
    component: Component | null;
}) => {
    const planQuery = useQuery({
        queryKey: ["plan", env.name, component],
        queryFn: async () => {
            const res = await axios.post(`/terraform/plan`, null, {
                params: {
                    component: component,
                    env_name: env.name,
                },
                timeout: 1000 * 60 * 5,
            });
            return res.data;
        },
        enabled: component !== null,
        staleTime: Infinity,
    });

    useEffect(() => {
        if (show) {
            planQuery.refetch();
        }
    }, [show]);

    return (
        <Modal show={show} onHide={() => setShow(false)} size="xl" backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>
                    Infrastructure Plan for <code>{component}</code>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {planQuery.isFetching && (
                    <div className="d-flex flex-column align-items-center">
                        <Spinner animation="border" />
                        <br />
                        Planning... please wait
                    </div>
                )}
                {planQuery.isSuccess && !planQuery.isFetching && (
                    <SyntaxHighlighter
                        className="d-flex flex-column-reverse"
                        style={darcula}
                        customStyle={{ maxHeight: "80vh" }}
                        showLineNumbers={false}
                        wrapLongLines={true}
                    >
                        {planQuery.data.join("\n")}
                    </SyntaxHighlighter>
                )}
            </Modal.Body>
        </Modal>
    );
};

const EnvironmentActionConfirmationModal = ({
    show,
    setShow,
    env,
    eventType,
    keyDownOverride,
}: {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    env: Environment;
    eventType: EventType | null;
    keyDownOverride: boolean;
}) => {
    const [confirmText, setConfirmText] = useState<string>("");

    const resetForm = () => {
        setConfirmText("");
    };

    const queryClient = useQueryClient();

    const getActionStub = () => {
        if (eventType === EventType.PERSISTENT_APPLY || eventType === EventType.EPHEMERAL_APPLY) {
            return Action.APPLY;
        }
        if (eventType === EventType.PERSISTENT_DESTROY || eventType === EventType.EPHEMERAL_DESTROY) {
            return Action.DESTROY;
        }
    };

    const getActionComponent = () => {
        if (eventType === EventType.PERSISTENT_APPLY || eventType === EventType.PERSISTENT_DESTROY) {
            return Component.PERSISTENT;
        }
        if (eventType === EventType.EPHEMERAL_APPLY || eventType === EventType.EPHEMERAL_DESTROY) {
            return Component.EPHEMERAL;
        }
    };

    const actionMutation = useMutation(
        () =>
            axios.post(`/terraform/${getActionStub()}`, null, {
                params: {
                    env_name: env.name,
                    component: getActionComponent(),
                    forced: keyDownOverride,
                },
            }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("environments");
            },
        },
    );

    const handleAction = async () => {
        setShow(false);
        resetForm();
        setTimeout(async () => {
            await actionMutation.mutateAsync();
        }, 500);
    };

    const renderAlertMessage = () => {
        if (getActionStub() === Action.APPLY) {
            return (
                <Alert variant="warning">
                    Are you sure you want to run <code>{getActionStub()}</code> for <code>{env.name}</code>?
                    <hr />
                    This may cause <code>{getActionComponent()}</code> infrastructure downtime. You are highly
                    encouraged to run <code>plan</code> first to understand the changes that will be made.
                </Alert>
            );
        }
        if (getActionStub() === Action.DESTROY) {
            return (
                <Alert variant="danger">
                    Are you sure you want to run <code>{getActionStub()}</code> for <code>{env.name}</code>?
                    <hr />
                    This will permanently delete all <code>{getActionComponent()}</code> infrastructure associated with
                    this environment.
                </Alert>
            );
        }
    };

    const renderFooter = () => {
        if (confirmText.toLocaleLowerCase() !== env.name.toLocaleLowerCase()) {
            return;
        }
        if (getActionStub() === Action.APPLY) {
            return (
                <Modal.Footer>
                    <Button variant="outline-warning" onClick={handleAction}>
                        <FontAwesomeIcon icon={faRocket} />
                        &nbsp;&nbsp;Apply
                    </Button>
                </Modal.Footer>
            );
        }
        if (getActionStub() === Action.DESTROY) {
            return (
                <Modal.Footer>
                    <Button variant="outline-danger" onClick={handleAction}>
                        <FontAwesomeIcon icon={faBomb} />
                        &nbsp;&nbsp;Destroy
                    </Button>
                </Modal.Footer>
            );
        }
    };

    const getProgressBarVariant = () => {
        if (confirmText.length < env.name.length / 3) {
            return "success";
        }
        if (confirmText.length < (env.name.length / 3) * 2) {
            return "warning";
        }
        if (confirmText.length < env.name.length) {
            return "danger";
        }
    };

    return (
        <Modal
            show={show}
            onHide={() => {
                resetForm();
                setShow(false);
            }}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Confirm Action for <code>{env.name}</code>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {renderAlertMessage()}
                {keyDownOverride}
                <Form.Control
                    type="text"
                    placeholder={`Type '${env.name}' here to ${getActionStub()?.toLowerCase()}`}
                    value={confirmText}
                    onChange={(e) => setConfirmText(e.target.value)}
                />
                {confirmText.toLowerCase() !== env.name.toLowerCase() && (
                    <ProgressBar
                        variant={getProgressBarVariant()}
                        className="mt-3"
                        now={confirmText.length}
                        max={env.name.length}
                    />
                )}
            </Modal.Body>
            {renderFooter()}
        </Modal>
    );
};

const RestoreSnapshotModal = ({
    show,
    setShow,
    env,
}: {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    env: Environment;
}) => {
    const [confirmText, setConfirmText] = useState<string>("");
    const [snapshotFile, setSnapshotFile] = useState<File | null>(null);
    const [uploadProgress, setUploadProgress] = useState<number>(0);

    const restoreSnapshotMutation = useMutation(() => {
        let formData = new FormData();
        formData.append("snapshot_file", snapshotFile!);

        return axios.put("/snapshots/snapshot", formData, {
            params: {
                env_name: env.name,
            },
            onUploadProgress: (progressEvent) => {
                if (!progressEvent.total) return;
                setUploadProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100));
            },
            timeout: 600 * 1000, // 10 minutes
        });
    });

    const handleSubmit = async () => {
        await restoreSnapshotMutation.mutateAsync();
        setConfirmText("");
        setSnapshotFile(null);
        setShow(false);
    };

    const getProgressBarVariant = () => {
        if (confirmText.length < env.name.length / 3) {
            return "success";
        }
        if (confirmText.length < (env.name.length / 3) * 2) {
            return "warning";
        }
        if (confirmText.length < env.name.length) {
            return "danger";
        }
    };

    const renderRestoreForm = () => {
        if (confirmText.toLowerCase() !== env.name.toLowerCase()) return;
        return (
            <Form.Group controlId="restoreFile">
                <Form.Label>Snapshot file to restore from</Form.Label>
                <Form.Control
                    type="file"
                    accept=".potgame"
                    onChange={(e) => {
                        if ((e.target as HTMLInputElement).files) {
                            setSnapshotFile((e.target as any).files[0]);
                        }
                    }}
                />
            </Form.Group>
        );
    };

    const renderFooter = () => {
        if (confirmText.toLowerCase() !== env.name.toLowerCase()) return;
        return (
            <Modal.Footer>
                {!restoreSnapshotMutation.isLoading && (
                    <Button
                        variant="outline-danger"
                        disabled={!snapshotFile || restoreSnapshotMutation.isLoading}
                        onClick={handleSubmit}
                    >
                        Restore
                    </Button>
                )}
                {restoreSnapshotMutation.isLoading && (
                    <ProgressBar style={{ width: "100%" }} animated variant="success" now={uploadProgress} max={100} />
                )}
            </Modal.Footer>
        );
    };

    return (
        <Modal show={show} onHide={() => setShow(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    Restore Snapshot for <code>{env.name}</code>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert variant="danger">
                    Restoring a snapshot would: <br />
                    <ul>
                        <li>Wipe all game data from the game database</li>
                        <li>Potentially overwrite files</li>
                        <li>Reset all user information and credentials</li>
                    </ul>
                    Please make sure you understand the consequences of this action.
                </Alert>
                {confirmText.toLowerCase() !== env.name.toLowerCase() && (
                    <Form.Control
                        type="text"
                        placeholder={`Type '${env.name}' here to proceed`}
                        value={confirmText}
                        disabled={confirmText.toLowerCase() === env.name.toLowerCase()}
                        onChange={(e) => setConfirmText(e.target.value)}
                    />
                )}
                {confirmText.toLowerCase() !== env.name.toLowerCase() && (
                    <ProgressBar
                        variant={getProgressBarVariant()}
                        className="mt-3"
                        now={confirmText.length}
                        max={env.name.length}
                    />
                )}
                {renderRestoreForm()}
            </Modal.Body>
            {renderFooter()}
        </Modal>
    );
};

const DetailPanel = ({ env }: { env: Environment }) => {
    const [keyDownOverride, setKeyDownOverride] = useState<boolean>(false);

    const [showActionConfirmationModal, setShowActionConfirmationModal] = useState<boolean>(false);
    const [showKeysModal, setShowKeysModal] = useState<boolean>(false);
    const [showInitModal, setShowInitModal] = useState<boolean>(false);
    const [showRestoreModal, setShowRestoreModal] = useState<boolean>(false);

    const [showPlanModal, setShowPlanModal] = useState<boolean>(false);
    const [planComponent, setPlanComponent] = useState<Component | null>(null);

    const [actionEventType, setActionEventType] = useState<EventType | null>(null);

    const getButtonDisabledState = (eventType: EventType) => {
        if (keyDownOverride) {
            return false;
        }
        if (env.being_modified) {
            return true;
        }
        if (env.lifestage === EnvironmentLifestage.CREATED && eventType === EventType.PERSISTENT_APPLY) {
            return false;
        }
        if (
            env.lifestage === EnvironmentLifestage.PERSISTENTACTIVE &&
            (eventType === EventType.PERSISTENT_DESTROY || eventType === EventType.EPHEMERAL_APPLY)
        ) {
            return false;
        }
        if (
            (env.lifestage === EnvironmentLifestage.PREACTIVE || env.lifestage === EnvironmentLifestage.ACTIVE) &&
            eventType === EventType.EPHEMERAL_DESTROY
        ) {
            return false;
        }
        return true;
    };

    useEffect(() => {
        document.addEventListener(
            "keydown",
            (e) => {
                if (e.key === "Shift") {
                    setKeyDownOverride(true);
                }
            },
            false,
        );
        document.addEventListener(
            "keyup",
            (e) => {
                if (e.key === "Shift") {
                    setKeyDownOverride(false);
                }
            },
            false,
        );

        return () => {
            document.removeEventListener("keydown", () => {}, false);
            document.removeEventListener("keyup", () => {}, false);
        };
    }, []);

    return (
        <>
            <EnvironmentInitModal show={showInitModal} setShow={setShowInitModal} env={env} />
            <RestoreSnapshotModal show={showRestoreModal} setShow={setShowRestoreModal} env={env} />
            <EnvironmentKeysModal show={showKeysModal} setShow={setShowKeysModal} env={env} />
            <EnvironmentPlanModal show={showPlanModal} setShow={setShowPlanModal} env={env} component={planComponent} />
            <EnvironmentActionConfirmationModal
                show={showActionConfirmationModal}
                setShow={setShowActionConfirmationModal}
                env={env}
                eventType={actionEventType}
                keyDownOverride={keyDownOverride}
            />
            <div className="d-flex align-items-center">
                <h2 className="mb-0">{env.name}</h2>
                {(env.lifestage === EnvironmentLifestage.PREACTIVE ||
                    env.lifestage === EnvironmentLifestage.ACTIVE) && (
                    <>
                        <Button variant="" size="sm" onClick={() => setShowKeysModal(true)}>
                            <FontAwesomeIcon icon={faKey} size="xl" />
                        </Button>
                        <Button variant="" size="sm" href={`https://${env.name}.potentium.com.au`} target="_blank">
                            <FontAwesomeIcon icon={faGlobe} size="xl" />
                        </Button>
                    </>
                )}
            </div>
            <ul className="mt-4 mb-2 progress-tracker progress-tracker--text progress-tracker--center progress-tracker--theme-black">
                {Object.values(EnvironmentLifestage).map((lifestage, index) => {
                    let envLifestageIndex = Object.values(EnvironmentLifestage).indexOf(env.lifestage);

                    return (
                        <li
                            key={lifestage}
                            className={`progress-step 
                                ${index < envLifestageIndex && "is-complete"} 
                                ${index === envLifestageIndex && "is-active"}`}
                        >
                            <div className="progress-marker"></div>
                            <div className="progress-text">
                                <h5 className="progress-title">{lifestage}</h5>
                            </div>
                        </li>
                    );
                })}
            </ul>
            {env.being_modified && (
                <div className="my-4">
                    <div className="d-flex justify-content-center">
                        <p className="my-0">Environment is being modified...</p>
                    </div>
                    <ProgressBar animated now={100} variant="warning" />
                </div>
            )}
            <div className="mb-3 d-flex justify-content-center">
                <Dropdown className="me-3">
                    <Dropdown.Toggle disabled={!keyDownOverride && env.being_modified}>
                        <FontAwesomeIcon icon={faHouseMedicalFlag} />
                        &nbsp;&nbsp;Persistent
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            eventKey="1"
                            onClick={() => {
                                setPlanComponent(Component.PERSISTENT);
                                setShowPlanModal(true);
                            }}
                        >
                            <FontAwesomeIcon icon={faCloud} />
                            &nbsp;&nbsp;Plan
                        </Dropdown.Item>
                        <Dropdown.Item
                            eventKey="2"
                            disabled={getButtonDisabledState(EventType.PERSISTENT_APPLY)}
                            onClick={() => {
                                setActionEventType(EventType.PERSISTENT_APPLY);
                                setShowActionConfirmationModal(true);
                            }}
                        >
                            <FontAwesomeIcon icon={faRocket} />
                            &nbsp;&nbsp;Apply
                        </Dropdown.Item>
                        <Dropdown.Item
                            eventKey="3"
                            disabled={getButtonDisabledState(EventType.PERSISTENT_DESTROY)}
                            onClick={() => {
                                setActionEventType(EventType.PERSISTENT_DESTROY);
                                setShowActionConfirmationModal(true);
                            }}
                        >
                            <FontAwesomeIcon icon={faBomb} />
                            &nbsp;&nbsp;Destroy
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="me-3">
                    <Dropdown.Toggle disabled={!keyDownOverride && env.being_modified}>
                        <FontAwesomeIcon icon={faToiletPaper} />
                        &nbsp;&nbsp;Ephemeral
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            eventKey="1"
                            onClick={() => {
                                setPlanComponent(Component.EPHEMERAL);
                                setShowPlanModal(true);
                            }}
                        >
                            <FontAwesomeIcon icon={faCloud} />
                            &nbsp;&nbsp;Plan
                        </Dropdown.Item>
                        <Dropdown.Item
                            eventKey="2"
                            disabled={getButtonDisabledState(EventType.EPHEMERAL_APPLY)}
                            onClick={() => {
                                setActionEventType(EventType.EPHEMERAL_APPLY);
                                setShowActionConfirmationModal(true);
                            }}
                        >
                            <FontAwesomeIcon icon={faRocket} />
                            &nbsp;&nbsp;Apply
                        </Dropdown.Item>
                        <Dropdown.Item
                            eventKey="3"
                            disabled={getButtonDisabledState(EventType.EPHEMERAL_DESTROY)}
                            onClick={() => {
                                setActionEventType(EventType.EPHEMERAL_DESTROY);
                                setShowActionConfirmationModal(true);
                            }}
                        >
                            <FontAwesomeIcon icon={faBomb} />
                            &nbsp;&nbsp;Destroy
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                {env.lifestage === EnvironmentLifestage.ACTIVE && (
                    <Dropdown as={ButtonGroup} align="end">
                        <Button onClick={() => setShowInitModal(true)} disabled={env.being_modified}>
                            <FontAwesomeIcon icon={faSyringe} />
                            &nbsp;&nbsp;Initialise
                        </Button>
                        <Dropdown.Toggle split variant="dark" disabled={env.being_modified} />
                        <Dropdown.Menu variant="dark">
                            <Dropdown.Item eventKey="restore" onClick={() => setShowRestoreModal(true)}>
                                <FontAwesomeIcon icon={faIcicles} />
                                &nbsp;&nbsp;Restore
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )}
            </div>
            <EnvironmentLogs env={env} />
        </>
    );
};

const EnvironmentDetail = () => {
    const { envName } = useParams();

    const [showCreationMOdal, setShowCreationModal] = useState<boolean>(false);

    const envsQuery = useQuery({
        queryKey: "environments",
        queryFn: async () => {
            const { data } = await axios.get("/environments");
            return data;
        },
        refetchInterval: 5000,
        staleTime: 5000,
    });

    const renderEnvironmentListGroup = () => {
        if (envsQuery.isLoading) {
            return;
        }

        return envsQuery.data
            ?.sort((e1: Environment, e2: Environment) => e1.name.localeCompare(e2.name))
            .map((env: Environment, index: number) => (
                <Fragment key={env.name}>
                    {index > 0 && <hr className="my-0" />}
                    <ListGroup.Item
                        key={env.name}
                        action
                        href={`/environments/${env.name}`}
                        active={env.name === envName}
                    >
                        <div className="my-2">
                            <Card.Title>{env.name}</Card.Title>
                            {env.description && <Card.Subtitle className="text-muted">{env.description}</Card.Subtitle>}
                            <Card.Text>Lifestage: {env.lifestage}</Card.Text>
                            {env.being_modified && (
                                <>
                                    <FontAwesomeIcon icon={faSpinner} spin />
                                    &nbsp;Environment is being modified...
                                </>
                            )}
                        </div>
                    </ListGroup.Item>
                </Fragment>
            ));
    };

    return (
        <div>
            <div className="d-flex justify-content-between mx-5 mt-2 mb-3">
                <h1>Environments</h1>
                <div>
                    <Button
                        className="mx-2"
                        variant="outline-primary"
                        size="sm"
                        disabled={envsQuery.isFetching}
                        onClick={() => envsQuery.refetch()}
                    >
                        <FontAwesomeIcon icon={faRotate} size="xl" />
                    </Button>
                    <Button variant="primary" onClick={() => setShowCreationModal(true)}>
                        <FontAwesomeIcon icon={faPlus} />
                        &nbsp;&nbsp;Create
                    </Button>
                </div>
            </div>
            <EnvironmentCreationModal show={showCreationMOdal} setShow={setShowCreationModal} />
            <Row className="mx-2">
                <Col md={3}>
                    <ListGroup variant="flush">{renderEnvironmentListGroup()}</ListGroup>
                </Col>
                <Col md={9}>
                    {envsQuery.isLoading && <Spinner />}
                    {envsQuery.isSuccess && (
                        <DetailPanel env={envsQuery.data.find((env: Environment) => env.name === envName)} />
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default EnvironmentDetail;
