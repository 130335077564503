import {
    faArrowRight,
    faBomb,
    faBurger,
    faEdit,
    faPaperPlane,
    faSpinner,
    faSync,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import { useState } from "react";
import {
    Alert,
    Button,
    ButtonGroup,
    Card,
    Dropdown,
    Form,
    InputGroup,
    Modal,
    OverlayTrigger,
    Spinner,
    Tooltip,
} from "react-bootstrap";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
    ComponentVersion,
    Environment,
    EnvironmentEditForm,
    EnvironmentLifestage,
    EnvironmentPatchPayload,
} from "../../types/Environment";
import axios from "../../utils/axios";
import { SpaceBetween } from "../../utils/spacing";
import "./EnvironmentCard.scss";

const DeletionConfirmationModal = ({
    show,
    setShow,
    envName,
}: {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    envName: string;
}) => {
    const [confirmText, setConfirmText] = useState<string>("");

    const queryClient = useQueryClient();

    const deleteEnvMutation = useMutation((envName: string) =>
        axios.delete(`/environments`, {
            params: {
                env_name: envName,
            },
            timeout: 60000, // 1 minute
        }),
    );

    const handleDelete = async () => {
        await deleteEnvMutation.mutateAsync(envName);
        setShow(false);
        setTimeout(async () => {
            queryClient.invalidateQueries("environments");
        }, 500);
    };

    return (
        <Modal show={show} onHide={() => setShow(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    Deleting <code>{envName}</code>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert variant="danger">Type environment name below to confirm deletion.</Alert>
                <Form.Control
                    type="text"
                    placeholder={`Type ${envName} here to delete`}
                    value={confirmText}
                    onChange={(e) => setConfirmText(e.target.value)}
                    disabled={deleteEnvMutation.isLoading}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="outline-danger"
                    onClick={handleDelete}
                    disabled={confirmText.toLowerCase() !== envName.toLowerCase() || deleteEnvMutation.isLoading}
                >
                    <FontAwesomeIcon icon={faBomb} beat={deleteEnvMutation.isLoading} />
                    &nbsp;&nbsp;{!deleteEnvMutation.isLoading ? "Delete" : "Deleting"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const DetailAndEditModal = ({
    show,
    setShow,
    env,
}: {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    env: Environment;
}) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [wait, setWait] = useState<boolean>(false);

    const queryClient = useQueryClient();

    const getReposAndTagsQuery = useQuery({
        queryKey: "reposAndTags",
        queryFn: async () => {
            const { data } = await axios.get("/ecr/tags", {
                timeout: 10000,
            });
            return data;
        },
        enabled: show,
    });

    const patchEnvironmentMutation = useMutation({
        mutationFn: async (payload: EnvironmentPatchPayload) => {
            const { data } = await axios.patch(`/environments/${env.name}`, payload, {
                params: {
                    wait: wait,
                },
                timeout: wait ? 10 * 60 * 1000 : 60 * 1000, // 10 minutes if wait else 1 minute
            });
            return data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries("environments");
        },
    });

    const handleSubmit = async (values: EnvironmentEditForm) => {
        if (!getReposAndTagsQuery.isSuccess) return;

        let payload = {} as EnvironmentPatchPayload;

        if (values.description !== "") {
            payload.description = values.description;
        }

        if (values.component_versions && values.component_versions?.length > 0) {
            // Remove component versions that are not changed from the payload
            payload.component_versions = values.component_versions?.filter(
                (cv: ComponentVersion) =>
                    cv.version !==
                    env.component_versions?.find((ecv: ComponentVersion) => ecv.component === cv.component)?.version,
            );
            if (payload.component_versions.length === 0) delete payload.component_versions;
        }

        if (values.refreshComponents && values.refreshComponents?.length > 0) {
            payload.refresh_components = values.refreshComponents;
        }

        if (JSON.stringify(payload) !== "{}") {
            await patchEnvironmentMutation.mutateAsync(payload);
        }
        setIsEditing(false);
        setShow(false);
    };

    return (
        <Modal
            show={show}
            onHide={() => {
                setIsEditing(false);
                setWait(false);
                setShow(false);
            }}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Detail for <code>{env.name}</code>
                </Modal.Title>
            </Modal.Header>
            {getReposAndTagsQuery.isLoading && (
                <Modal.Body className="d-flex justify-content-around my-5">
                    <Spinner />
                </Modal.Body>
            )}
            {getReposAndTagsQuery.isSuccess && (
                <Formik
                    initialValues={{
                        description: env.description ? env.description : "",
                        component_versions: env.component_versions,
                    }}
                    onSubmit={async (values: EnvironmentEditForm, { resetForm }: { resetForm: () => void }) => {
                        await handleSubmit(values);
                        resetForm();
                    }}
                >
                    {({
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                    }: {
                        values: EnvironmentEditForm;
                        handleChange: any;
                        handleBlur: any;
                        handleSubmit: any;
                        setFieldValue: any;
                    }) => (
                        <Form onSubmit={handleSubmit}>
                            <Modal.Body>
                                <SpaceBetween>
                                    <Form.Group controlId="formDescription">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="description"
                                            value={values.description}
                                            placeholder="(Optional) Enter a description for the environment"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled={!isEditing || patchEnvironmentMutation.isLoading}
                                        />
                                    </Form.Group>
                                    {values.component_versions && values.component_versions.length > 0 && (
                                        <Form.Group controlId="formComponentVersions">
                                            <Form.Label>Component Versions</Form.Label>
                                            {values.component_versions.map(
                                                (componentVersion: ComponentVersion, index: number) => (
                                                    <InputGroup key={componentVersion.component}>
                                                        <InputGroup.Text style={{ minWidth: "270px" }}>
                                                            {componentVersion.component}
                                                        </InputGroup.Text>
                                                        <Form.Select
                                                            name={`component_versions[${index}].version`}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={componentVersion.version}
                                                            disabled={
                                                                !isEditing ||
                                                                patchEnvironmentMutation.isLoading ||
                                                                env.name === "dev"
                                                            }
                                                        >
                                                            <option key={"dev"}>dev</option>
                                                            {getReposAndTagsQuery.data[componentVersion.component].map(
                                                                (t: string) => (
                                                                    <option key={t}>{t}</option>
                                                                ),
                                                            )}
                                                        </Form.Select>
                                                        {isEditing &&
                                                            [
                                                                EnvironmentLifestage.PREACTIVE,
                                                                EnvironmentLifestage.ACTIVE,
                                                            ].includes(env.lifestage) && (
                                                                <OverlayTrigger
                                                                    placement="right"
                                                                    overlay={<Tooltip>Refresh component</Tooltip>}
                                                                >
                                                                    <Button
                                                                        variant={
                                                                            values.refreshComponents?.includes(
                                                                                componentVersion.component,
                                                                            )
                                                                                ? "info"
                                                                                : ""
                                                                        }
                                                                        disabled={patchEnvironmentMutation.isLoading}
                                                                        onClick={() => {
                                                                            // If the refreshComponents array is not initialized, initialize it with the component name
                                                                            if (!values.refreshComponents) {
                                                                                setFieldValue("refreshComponents", [
                                                                                    componentVersion.component,
                                                                                ]);
                                                                                return;
                                                                            }
                                                                            // If the component is already in the refreshComponents array, remove it
                                                                            if (
                                                                                values.refreshComponents.includes(
                                                                                    componentVersion.component,
                                                                                )
                                                                            ) {
                                                                                setFieldValue(
                                                                                    "refreshComponents",
                                                                                    values.refreshComponents.filter(
                                                                                        (c: string) =>
                                                                                            c !==
                                                                                            componentVersion.component,
                                                                                    ),
                                                                                );
                                                                                return;
                                                                            }
                                                                            // If the component is not in the refreshComponents array, add it
                                                                            setFieldValue(
                                                                                "refreshComponents",
                                                                                values.refreshComponents.concat(
                                                                                    componentVersion.component,
                                                                                ),
                                                                            );
                                                                        }}
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={faSync}
                                                                            spin={
                                                                                patchEnvironmentMutation.isLoading &&
                                                                                values.refreshComponents?.includes(
                                                                                    componentVersion.component,
                                                                                )
                                                                            }
                                                                        />
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            )}
                                                    </InputGroup>
                                                ),
                                            )}
                                        </Form.Group>
                                    )}
                                </SpaceBetween>
                            </Modal.Body>
                            <Modal.Footer>
                                {!isEditing && (
                                    <Button variant="outline-primary" onClick={() => setIsEditing(true)}>
                                        <FontAwesomeIcon icon={faEdit} />
                                        &nbsp;&nbsp;Edit
                                    </Button>
                                )}
                                {isEditing && (
                                    <Dropdown as={ButtonGroup}>
                                        <Button type="submit" disabled={patchEnvironmentMutation.isLoading}>
                                            <FontAwesomeIcon
                                                icon={faPaperPlane}
                                                beat={patchEnvironmentMutation.isLoading}
                                            />
                                            &nbsp;&nbsp;{patchEnvironmentMutation.isLoading ? "Saving" : "Save"}
                                        </Button>
                                        <Dropdown.Toggle
                                            variant="dark"
                                            split
                                            disabled={patchEnvironmentMutation.isLoading}
                                        />
                                        <Dropdown.Menu variant="dark" align="end">
                                            <Dropdown.Item
                                                onClick={() => {
                                                    setWait(true);
                                                    handleSubmit(values);
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPaperPlane}
                                                    beat={patchEnvironmentMutation.isLoading}
                                                />
                                                &nbsp;&nbsp;Save and Wait
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                )}
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            )}
        </Modal>
    );
};

const EnvironmentCard = ({ environment }: { environment: Environment }) => {
    const [showDeletionConfirmationModal, setShowDeletionConfirmationModal] = useState<boolean>(false);
    const [showEnvDetailModal, setShowEnvDetailModal] = useState<boolean>(false);

    return (
        <Card key={environment.name} className="environment-card">
            <DeletionConfirmationModal
                show={showDeletionConfirmationModal}
                setShow={setShowDeletionConfirmationModal}
                envName={environment.name}
            />
            <DetailAndEditModal show={showEnvDetailModal} setShow={setShowEnvDetailModal} env={environment} />
            <Card.Header>
                <Card.Title>{environment.name}</Card.Title>
                <Card.Subtitle>{environment.description}</Card.Subtitle>
            </Card.Header>
            <Card.Body className="pb-0">
                <Form.Label>Lifestage</Form.Label>
                <p>{environment.lifestage}</p>
                <Form.Label>Last Modified At</Form.Label>
                <p>{new Date(environment.last_modified_at).toLocaleString()}</p>
                <Form.Label>Last Modified By</Form.Label>
                <p>{environment.last_modified_by}</p>
            </Card.Body>
            <Card.Footer>
                {environment.being_modified ? (
                    <div className="d-flex">
                        <div className="d-flex align-items-center">
                            <FontAwesomeIcon icon={faSpinner} spin size="lg" />
                        </div>
                        <div className="flex-grow-1" />
                        <Button className="ms-2" href={`/environments/${environment.name}`}>
                            <FontAwesomeIcon icon={faArrowRight} size="lg" />
                        </Button>
                    </div>
                ) : (
                    <div className="d-flex">
                        {environment.lifestage === EnvironmentLifestage.CREATED && (
                            <Button
                                size="sm"
                                variant="outline-danger"
                                onClick={() => setShowDeletionConfirmationModal(true)}
                            >
                                <FontAwesomeIcon icon={faTrash} size="xl" />
                            </Button>
                        )}
                        <div className="flex-grow-1" />
                        <Button onClick={() => setShowEnvDetailModal(true)}>
                            <FontAwesomeIcon icon={faBurger} />
                            &nbsp;&nbsp;Detail
                        </Button>
                        <Button className="ms-2" href={`/environments/${environment.name}`}>
                            <FontAwesomeIcon icon={faArrowRight} size="lg" />
                        </Button>
                    </div>
                )}
            </Card.Footer>
        </Card>
    );
};

export default EnvironmentCard;
