import { Button, Container, Dropdown, Form, InputGroup, Modal, Spinner, SplitButton } from "react-bootstrap";
import axios from "../../utils/axios";

import { useState } from "react";

import { faPaperPlane, faPlus, faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as Yup from "yup";
import EnvironmentCard from "../../components/EnvironmentCard/EnvironmentCard";
import { Environment, EnvironmentCreationForm, EnvironmentCreationPayload } from "../../types/Environment";
import { SpaceBetween } from "../../utils/spacing";
import "./Environments.scss";

export const EnvironmentCreationModal = ({
    show,
    setShow,
}: {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const queryClient = useQueryClient();

    const createEnvironmentMutation = useMutation(
        (data: EnvironmentCreationPayload) => axios.post("/environments", data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("environments");
            },
        },
    );

    const getReposAndTagsQuery = useQuery({
        queryKey: "reposAndTags",
        queryFn: async () => {
            const { data } = await axios.get("/ecr/tags", {
                timeout: 10000,
            });
            return data;
        },
        enabled: show,
    });

    const schema = Yup.object().shape({
        name: Yup.string()
            .matches(
                new RegExp("^[a-zA-Z0-9-]{3,15}$"),
                "Environment names must only contain alphanumeric characters and be between 3 and 15 characters long.",
            )
            .required("Name is required"),
        description: Yup.string(),
    });

    const getInitialCompVers = () => {
        const compVers = {};
        Object.keys(getReposAndTagsQuery.data)
            .filter((k: string) => getReposAndTagsQuery.data[k].length > 0)
            .forEach((k: string) => {
                // (compVers as any)[k] = getReposAndTagsQuery.data[k][0];
                (compVers as any)[k] = "dev";
            });
        return compVers;
    };

    const handleSubmit = async ({ values, resetForm }: { values: EnvironmentCreationForm; resetForm: () => void }) => {
        if (!getReposAndTagsQuery.isSuccess) return;

        // Remove empty fields
        Object.keys(values).forEach(
            (key: string) =>
                values[key as keyof typeof EnvironmentCreationModal] === "" &&
                delete values[key as keyof typeof EnvironmentCreationModal],
        );
        let mutationPayload: EnvironmentCreationPayload = {
            name: values.name,
            description: values.description,
            component_versions: Object.keys(values.componentVersions).map((k: string) => {
                return {
                    component: k,
                    version: (values.componentVersions as any)[k],
                };
            }),
        };
        await createEnvironmentMutation.mutateAsync(mutationPayload);
        setShow(false);
        resetForm();
    };

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Create Environment</Modal.Title>
            </Modal.Header>
            {getReposAndTagsQuery.isLoading && (
                <Modal.Body className="d-flex justify-content-around my-5">
                    <Spinner />
                </Modal.Body>
            )}
            {getReposAndTagsQuery.isSuccess && (
                <Formik
                    validationSchema={schema}
                    onSubmit={(values: EnvironmentCreationForm, { resetForm }) => handleSubmit({ values, resetForm })}
                    initialValues={{
                        name: "",
                        description: "",
                        componentVersions: getInitialCompVers(),
                    }}
                >
                    {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Body>
                                <SpaceBetween>
                                    <Form.Group controlId="formName">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            isInvalid={!!errors.name}
                                            placeholder="Enter a name for the environment"
                                            disabled={createEnvironmentMutation.isLoading}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="formDescription">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="description"
                                            value={values.description}
                                            onChange={handleChange}
                                            isInvalid={!!errors.description}
                                            placeholder="(Optional) Enter a description for the environment"
                                            disabled={createEnvironmentMutation.isLoading}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.description}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="formComponentVersions">
                                        <Form.Label>Component Versions</Form.Label>
                                        {Object.keys(getReposAndTagsQuery.data)
                                            .filter((k: string) => getReposAndTagsQuery.data[k].length > 0)
                                            .sort((k1: string, k2: string) => k1.localeCompare(k2))
                                            .map((rt: any) => (
                                                <InputGroup key={rt}>
                                                    <InputGroup.Text style={{ minWidth: "300px" }}>
                                                        {rt}
                                                    </InputGroup.Text>
                                                    <Form.Select
                                                        name={`componentVersions.${rt}`}
                                                        onChange={handleChange}
                                                        value={(values.componentVersions as any)[rt]}
                                                        disabled={createEnvironmentMutation.isLoading}
                                                    >
                                                        <option key={"dev"}>dev</option>
                                                        {getReposAndTagsQuery.data[rt].map((t: string) => (
                                                            <option key={t}>{t}</option>
                                                        ))}
                                                    </Form.Select>
                                                </InputGroup>
                                            ))}
                                    </Form.Group>
                                </SpaceBetween>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    disabled={!isValid || createEnvironmentMutation.isLoading}
                                >
                                    <FontAwesomeIcon icon={faPaperPlane} beat={createEnvironmentMutation.isLoading} />
                                    &nbsp;&nbsp;{!createEnvironmentMutation.isLoading ? "Create" : "Creating"}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            )}
        </Modal>
    );
};

const Environments = () => {
    const [showCreationModal, setShowCreationModal] = useState(false);

    const [refreshInterval, setRefreshInterval] = useState(10000);

    const envsQuery = useQuery({
        queryKey: "environments",
        queryFn: async () => {
            const { data } = await axios.get("/environments");
            return data;
        },
        refetchInterval: refreshInterval,
        staleTime: refreshInterval,
    });

    const renderEnvironmentCards = () => {
        if (envsQuery.isLoading) {
            return;
        }
        if (envsQuery.data.length === 0) {
            return <h3>No environments found</h3>;
        }
        return envsQuery.data
            ?.sort((e1: Environment, e2: Environment) => e1.name.localeCompare(e2.name))
            .map((env: Environment) => <EnvironmentCard key={env.name} environment={env} />);
    };

    return (
        <Container className="mt-5 environments-ctr">
            <EnvironmentCreationModal show={showCreationModal} setShow={setShowCreationModal} />
            <div className="d-flex justify-content-end mb-3">
                <SplitButton
                    variant="outline-primary"
                    align="end"
                    className="mx-2"
                    size="sm"
                    title={<FontAwesomeIcon icon={faRotate} size="xl" />}
                >
                    <Dropdown.ItemText>Auto Refresh</Dropdown.ItemText>

                    <Dropdown.Item onClick={() => setRefreshInterval(1000)} active={refreshInterval === 1000}>
                        1s
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setRefreshInterval(5000)} active={refreshInterval === 5000}>
                        5s
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setRefreshInterval(10000)} active={refreshInterval === 10000}>
                        10s
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setRefreshInterval(30000)} active={refreshInterval === 30000}>
                        30s
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setRefreshInterval(60000)} active={refreshInterval === 60000}>
                        1m
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={() => setRefreshInterval(0)} active={refreshInterval === 0}>
                        Off
                    </Dropdown.Item>
                </SplitButton>

                <Button variant="primary" onClick={() => setShowCreationModal(true)}>
                    <FontAwesomeIcon icon={faPlus} />
                    &nbsp;&nbsp;Create
                </Button>
            </div>
            <div className="environment-cards">{renderEnvironmentCards()}</div>
        </Container>
    );
};

export default Environments;
