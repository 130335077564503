import axios from "axios";
import { Auth } from "aws-amplify";

axios.defaults.baseURL = "https://api.env-mgr.potentium.com.au";
// axios.defaults.baseURL = "http://localhost:7200";
axios.defaults.timeout = 5000; // 5 seconds

axios.interceptors.request.use(
    async (config) => {
        const session = await Auth.currentSession();
        config.headers.Authorization = `Bearer ${session.getAccessToken().getJwtToken()}`;
        return config;
    },
    (error) => {
        Promise.reject(error);
    },
);

export default axios;
