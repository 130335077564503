export interface Event {
    env_name: string;
    timestamp: string;
    actioned_by: string;
    event_type: EventType;
    message?: string;
}

export enum EventType {
    PERSISTENT_INIT = "PersistentInit",
    PERSISTENT_APPLY = "PersistentApply",
    PERSISTENT_DESTROY = "PersistentDestroy",

    EPHEMERAL_INIT = "EphemeralInit",
    EPHEMERAL_APPLY = "EphemeralApply",
    EPHEMERAL_DESTROY = "EphemeralDestroy",
}
