import { Environment } from "../../types/Environment";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";

import "./EnvironmentLogs.scss";
import { useQuery } from "react-query";

import axios from "../../utils/axios";
import { LogEntry } from "../../types/Log";
import { useEffect } from "react";

const EnvironmentLogs = ({ env }: { env: Environment }) => {
    const logsQuery = useQuery({
        queryKey: ["environment-logs", env.name],
        queryFn: async () => {
            const { data } = await axios.get("/logs", {
                params: {
                    env_name: env.name,
                    last_n: 50,
                },
            });
            return data;
        },
        refetchInterval: 1000,
        staleTime: 1000,
        enabled: env.being_modified,
    });

    useEffect(() => {
        logsQuery.refetch();
    }, [env.being_modified]);

    const getLogContent = () => {
        if (logsQuery.isLoading) {
            return ["Loading..."];
        }
        if (!logsQuery.data || logsQuery.data.length === 0) {
            return ["No logs found"];
        }
        return logsQuery.data?.map((log: LogEntry) => log.message);
    };

    return (
        <div className="environment-logs-ctr">
            <h3>Logs</h3>
            <SyntaxHighlighter
                className={`log-panel ${env.being_modified ? "being-modified" : ""} ${
                    getLogContent().length > 25 ? "long-enough" : ""
                }`}
                style={darcula}
                showLineNumbers={false}
                wrapLongLines={true}
            >
                {getLogContent().join("\n")}
            </SyntaxHighlighter>
        </div>
    );
};

export default EnvironmentLogs;
